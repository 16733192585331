.js-app-screenshot {
    width: 60px;
    height: 400px;
    flex: 0.7;
}

.icon-iterations {
    display: flex;
    margin-top: 40px;
    margin-left: 280px;
}

.icon-image {
    width: 100px;
    height: 100px;
    border-radius: 10px;
}

.wireframe-container {
    display: flex;
    margin-top: 40px;
    margin-left: 140px;
    gap: 40px;
}

.wireframe-image {
    width: 220px;
    border-radius: 28px;
}

.homepage-iterations {
    display: flex;
    margin-top: 40px;
    margin-left: 100px;
    gap: 20px;
}

.homepage-image {
    width: 200px;
    border-radius: 27px;
}

.avatar-image-container {
    display: flex;
    align-items: center;
    margin-top: 20px;
    margin-left: 100px;
    gap: 15px;
}

.ava-image {
    width: 280px;
    border-radius: 10px;
}

.fava-image {
    width: 230px;
    border-radius: 10px;
}

.cosmetic-image-container {
    display: flex;
    align-items: center;
    margin-top: 20px;
    margin-left: 100px;
    gap: 35px;
}

.cosmetic-image {
    width: 250px;
    border-radius: 10px;
}

.cosmetic-hair-image {
    width: 280px;
    border-radius: 10px;
}

.cosmetic-tall-image {
    width: 180px;
    border-radius: 10px;
}

.medal-trophy-container {
    display: flex;
    align-items: center;
    margin-top: 0px;
    margin-left: 120px;
    gap: 35px;
}

.medal-image {
    width: 80px;
}

.trophy-image {
    width: 120px;
}

.banner-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
    margin-left: 50px;
    margin-top: 30px;
}

.banner-image {
    width: 500px;
}

.environment-container {
    margin-top: 50px;
    margin-left: 200px;
    display: flex;
    gap: 70px;
    
}

.env-image {
    width: 300px;
}

.video-container {
    margin-top: 30px;
    margin-left: 250px;
}

