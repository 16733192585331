.borderedDivLight {
    position: relative;
    width: 600px; 
    min-width: 550px;
    height: 300px; 
    background-color: rgba(255, 255, 255, 0.9); /* Just for demonstration, use your desired color */
    overflow: hidden;  /* Hide overflow to prevent overflow of pseudo-elements */
    text-align: center;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
    transition: background-color 0.3s ease, box-shadow 0.5s ease;
  }
  
  /* Diagonal border styles for top left corner */
  .borderedDivLight::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100px;
    height: 100px;
    border-top: 2px solid rgba(0,0,0,0.8);
    border-left: 2px solid rgba(0,0,0,0.8);
  }
  
  /* Diagonal border styles for bottom right corner */
  .borderedDivLight::after {
    content: '';
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100px;
    height: 100px;
    border-bottom: 2px solid rgba(0,0,0,0.8);
    border-right: 2px solid rgba(0,0,0,0.8);
  }

  .borderedDivDark {
    position: relative;
    width: 600px; 
    min-width: 550px;
    height: 300px; 
    background-color: rgba(0, 0, 0, 0.8);
    overflow: hidden;
    text-align: center;
    box-shadow: 0px 0px 20px rgba(207, 207, 207, 0.2);
    transition: background-color 0.3s ease, box-shadow 0.5s ease;
  }
  
  /* Diagonal border styles for top left corner */
  .borderedDivDark::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100px;
    height: 100px;
    border-top: 2px solid rgb(255, 255, 255);
    border-left: 2px solid rgb(255, 255, 255);
  }
  
  /* Diagonal border styles for bottom right corner */
  .borderedDivDark::after {
    content: '';
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100px;
    height: 100px;
    border-bottom: 2px solid rgb(255, 255, 255);
    border-right: 2px solid rgb(255, 255, 255);
  }

  .anon-name, .anon-job-title, .anon-description {
    margin: 0;
    font-family: 'unicaone', sans-serif;
  }

  /*
.anon-name {
    font-size: 42px;
    margin-top: 25px;
    margin-bottom: 4px;
}
*/

.anon-job-title {
    font-size: 26px;
    font-weight: 100;
    color: rgb(114, 114, 114); 
    margin-bottom: 20px;
}

.anon-description {
    font-size: 20px;
    padding-left: 20px;
    padding-right: 20px;
}

.anon-name {
  font-size: 42px;
  margin-top: 25px;
  margin-bottom: 4px;
  display: inline-block;
  overflow: hidden;
}

.anon-name span {
  opacity: 0;
  display: inline-block;
  animation: typing-animation 0.4s forwards steps(1);
}

@keyframes typing-animation {
  to {
    opacity: 1;
  }
}