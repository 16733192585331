.App {
  overflow-x: hidden;
}
/*
Light Mode background: rgb(245, 245, 245);
Light Mode Card: rgb (255, 255, 255);
Dark Mode Background: rgb(28, 28, 30)
Dark Mode card: rgb(44, 44, 46);

Dark Gradient for Pocket Cipher: #4D2997
Light Gradient for Pocket Cipher: #BE32FB
*/
Body {
  padding-top: 70px;
}

#tsparticlesdark { 
  height: 100vh;
  position: fixed;
  left: 0;
  right: 0;
  margin: 0;
  padding: 0;
  margin-top: -70px;
  margin-bottom: -100px;
  z-index: 0;
  background: radial-gradient(circle, #272727, #000000);
}

#tsparticleslight {
  height: 100vh;
  position: fixed;
  left: 0;
  right: 0;
  margin: 0;
  padding: 0;
  margin-top: -70px;
  margin-bottom: -100px;
  z-index: 0;
  background: radial-gradient(circle, #ffffff, #c4c4c4)
}
