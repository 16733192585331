.animated-line {
    position: absolute;
    top: 40%;
    left: 0;
    z-index: -1;
  }
  
.animated-line-two {
    position: absolute;
    top: 40%;
    right: 0;
    z-index: -1;
}

.animated-line-three {
    position: absolute;
    top: 160%;
    left: 0;
    z-index: -1;
}

.animated-line-four {
    position: absolute;
    top: 160%;
    right: 0;
    z-index: -1;
}

.line {
    stroke-dasharray: 600; /* Set the length of the visible portion */
    stroke-dashoffset: 600;
    animation: draw 4s linear infinite; /* Animation should be infinite to continuously loop */
  }
  
  @keyframes draw {
    0% {
      stroke-dashoffset: 600;
    }
    50% {
      stroke-dashoffset: 0;
    }
    100% {
      stroke-dashoffset: -600; /* Adjust this value to ensure the line travels the full path */
    }
  }
  