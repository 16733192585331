.back-page-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 30px;
    padding-bottom: 20px;
}

/* Section one */
.icon-name-container {
    display: flex;
    vertical-align: top;
    margin-top: 40px;
    margin-left: 90px;
}

.pc-page-logo {
    width: 100px;
    height: 100px;
    border-radius: 10px;
}

.name-description-container {
    margin-left: 20px;
    display: flex;
    flex-direction: column;
}

.free-button {
    width: 70px;
    height: 30px;
    margin-top: 10px;
    background: rgb(10, 132, 255);
    color: white;
    position: relative;
}

.free-title {
    position: absolute;
    left: 18px;
    top: 2px;
    color: white;
    font-family: 'SF-Pro-Medium', sans-serif;
    font-size: 18px;
}
/* Section one */

/* Section Two */
.screenshot-information-container {
    display: flex;
    margin-top: 20px;
    margin-left: 95px;
    margin-right: 90px;
    align-items: center;

}

.pc-app-screenshot {
    width: 60px;
    height: 400px;
    flex: 0.7;
}

.design-iterations {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.iteration-image {
    width: 480px;
    border-radius: 10px;
}

.development-iterations {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.development-image {
    width: 660px;
    border-radius: 10px;
}

.tool-big-git {
    width: 50px;
    height: 50px;
    border-radius: 25px;
    padding: 1px 1px;
    background-color: rgb(177, 177, 177);
}