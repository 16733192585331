
.title-visit-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100px;
}

.contribution-tools-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.tool-container {
    margin-top: 50px;
    display: flex;
    gap: 30px;
}

.tool-big-image {
    width: 50px;
    height: 50px;
    border-radius: 10px;
}

.tool-big-gimp {
    width: 70px;
    height: 50px;
}

.tool-big-git {
    width: 50px;
    height: 50px;
    border-radius: 25px;
    padding: 1px 1px;
    background-color: rgb(177, 177, 177);
}

.tool-big-figma {
    width: 70px;
    height: 50px;
}

.graphic-container {
    display: flex;
    gap: 30px;
    margin-top: 50px;
    margin-left: 200px;
}

.iphone-ls-image {
    width: 150px;
    height: 300px;
}

.website-graphic {
    width: 500px;
    height: 300px;
}

.controllerfont-titles {
    margin-top: 40px;
    margin-left: 200px;
    display: flex;
    align-items: center;
    gap: 30px;
}

.controller-title {
    margin-top: 20px;
    width: 300px;
}

.js-site-screenshots {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
}

.js-site-screenshot {
    width: 700px;
    border-radius: 20px;
}

.js-site-screenshot-anon {
    width: 700px;
    
}