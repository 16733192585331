.generic-image {
    width: 70px;
    height: 70px;
    border-radius: 10px;
    cursor: pointer;
  }

  .git-image {
    border-radius: 50%;
    background-color: gray;
  }

  .figma-image {
    width: 80px;
    height: 60px;
    cursor: pointer;
  }

  .gimp-image {
    width: 90px;
    height: 60px;
    cursor: pointer;
  }

  .sql-image {
    width: 120px;
    height: 60px;
    cursor: pointer;
  }

  .vscode-image {
    width: 60px;
    height: 60px;
    cursor: pointer;
  }
  

  /* Modify the scrollbar for webkit-based browsers */
.skills-list-light::-webkit-scrollbar {
    width: 10px; /* Width of the scrollbar */  
  }
.skills-list-dark::-webkit-scrollbar {
  width: 10px; /* Width of the scrollbar */
}
  
  /* Track (the area behind the scrollbar) */
  .skills-list-light::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
    border: 1px solid rgb(226, 226, 226);
  }
  .skills-list-dark::-webkit-scrollbar-track {
    background: #6b6b6b;
    border-radius: 10px;
  }
  
  /* Handle (the draggable part of the scrollbar) */
  .skills-list-light::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
    border: 2px solid rgb(226, 226, 226);
  }

  .skills-list-dark::-webkit-scrollbar-thumb {
    background: #1e1e1e;
    border-radius: 10px;
    border: 1px solid rgb(132, 132, 132);
  }
  
  /* Handle on hover - make draggable bar darker */
  .skills-list-light::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  .skills-list-light::-webkit-scrollbar-thumb:hover {
    background: rgb(0, 0, 0)636;
  }
  
 