
@keyframes animateGradient {
    0% {
        background-position: 0% 100%;
    }
    100% {
        background-position: 100% 50%;
    }
}

.animated-bottom-border-light {
    height: 2px;
    background-image: linear-gradient(to right, #f4c73f 0%, #e8c200 20%, rgb(231, 100, 39) 20%, rgb(241, 105, 51) 80%);
    background-size: 200% 100%;
    animation: pulseColor 12s linear infinite;
}

.animated-bottom-border-dark {
    height: 1px;
    background-image: linear-gradient(to right, #531790 0%, #8445c4 20%, rgba(92, 20, 20, 0.9) 20%, rgba(93, 22, 22, 0.9) 80%);
    background-size: 200% 100%;
    animation: pulseColor 12s linear infinite;
}

@keyframes pulseColor {
    0% {
        background-position: 100% 0;
    }
    100% {
        background-position: -100% 0;
    }
}

.cards-image {
    margin-top: 50px;
    margin-bottom: 40px;
    margin-left: 150px;
    border-radius: 20px;
    width: 700px;
}

.ios-ref-container {
    margin-top: 45px;
    margin-bottom: 20px;
    margin-left: 140px;
    display: flex;
    gap: 50px;
}

.toggler-image {
    width: 400px;
    height: 270px;
    border-radius: 20px; 
}

.ipad-image {
    width: 300px;
    border-radius: 20px;
}

/* Google classes */
.gemini-ref-img {
    width: 800px;
    height: 400px;
    margin-left: 100px;
    margin-top: 30px;
    margin-bottom: 30px;
    border-radius: 20px;
}

/* Anon classes */
.anon-porfolio-name {
    font-family: 'unicaone', sans-serif;
    font-size: 70px;
    margin-top: 40px;
    margin-left: 230px;
    display: inline-block;
    overflow: hidden;
  }

  .anon-porfolio-name span {
    opacity: 0;
    display: inline-block;
    animation: typing-animation 0.4s forwards steps(1);
  }
  
  @keyframes typing-animation {
    to {
      opacity: 1;
    }
  }

.anon-ref-holder {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 40px;
    margin-bottom: 40px;
    gap: 40px;
}

.anon-ref-image {
    width: 700px;
    height: 400px;

}