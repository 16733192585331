.skills-main-container {
    display: flex;
    margin-top: 20px;
}

.skills-anon-container {
    overflow-x: hidden; /* Enable horizontal scrolling */
    overflow-y: hidden; /* Disable vertical scrolling */
    padding-top: 10px;
  }

.chevron-button {
    margin-top: 5px;
    border: none;
    background: none;
    font-size: 50px;
    cursor: pointer;
}

.skill-anon-image {
    width: 70px;
    height: 70px;
    margin-left: 10px;
    margin-right: 10px;
}

.java-image {
  width: 70px;
  height: 70px;
  scale: 1.2;
  margin-left: 10px;
  margin-right: 10px;
}

.git-image {
  width: 65px;
  height: 65px;
  border-radius: 33px;
  margin-left: 5px;
  padding: 2px;
  margin-right: 5px;
  background-color: rgb(184, 184, 184);
}

.figma-image {
  width: 90px;
  height: 60px;
  margin: 0px -5px 5px -5px;
}

.gimp-image {
  width: 90px;
  height: 70px;
  padding: 0;
}

.vscode-image {
  width: 60px;
  height: 60px;
  padding-bottom: 5px;
  padding-left: 10px;
}

.sql-image {
  width: 120px;
  height: 60px;
  padding: 0;
}