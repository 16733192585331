/* import custom fonts */


/* iOS fonts */
@font-face {
    font-family: 'SF-Pro-Regular';
    src: url('../public/iosFonts/SF-Pro-Regular.woff') format('woff');
  }
  
@font-face {
    font-family: 'SF-Pro-Medium';
    src: url('../public/iosFonts/SF-Pro-Medium.woff') format('woff');
    }

@font-face {
    font-family: 'SF-Pro-Bold';
    src: url('../public/iosFonts/SF-Pro-Bold.woff') format('woff');
    }

@font-face {
    font-family: 'SF-Pro-Display-Thin';
    src: url('../public/iosFonts/SF-Pro-Display-Thin.woff') format('woff');
    }

@font-face {
    font-family: 'SF-Pro-Display-Ultralight';
    src: url('../public/iosFonts/SF-Pro-Display-Ultralight.woff') format('woff');
    }

@font-face {
    font-family: 'SF-Pro-Text-Thin';
    src: url('../public/iosFonts/SF-Pro-Text-Thin.woff') format('woff');
    }
    

@font-face {
    font-family: 'SF-Pro-Text-Ultralight';
    src: url('../public/iosFonts/SF-Pro-Text-Ultralight.woff') format('woff');
    }

/* ANON font */
@font-face {
    font-family: 'unicaone';
    src: url('../public/ANONfont/unicaone') format('woff');
    }

/* Google fonts */
@font-face {
    font-family: 'ProductSansLight';
    src: url('../public/GoogleFonts/productsans-light-webfont.woff') format('woff');
    }
@font-face {
    font-family: 'ProductSansMedium';
    src: url('../public/GoogleFonts/productsans-medium-webfont.woff') format('woff');
    }
@font-face {
    font-family: 'ProductSansRegular';
    src: url('../public/GoogleFonts/productsans-regular-webfont.woff') format('woff');
    }
                
@font-face {
    font-family: 'ProductSansBold';
    src: url('../public/GoogleFonts/productsans-bold-webfont.woff') format('woff');
    }
    