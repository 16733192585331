.example-cards {
    display: flex;
    background-color: black;
    padding: 10px 20px;
    width: 80%;
    border-radius: 20px;
    margin-left: 80px;
    margin-top: 50px;
    margin-bottom: 40px;
}

.card1, .card2, .card3 {
    width: 200px;
    height: 200px;
    margin-left: 40px;
    padding-left: 20px;
    border-radius: 12px;
}

.label1, .label2, .label3 {
    font-family: 'SF-Pro-Bold', sans-serif;
    font-size: 20px;
    margin-top: 20px;
}

.label1-2, .label1-3, .label2-2, .label2-3, .label3-2, .label3-3 {
    font-family: 'SF-Pro-Text-Thin', 'Times New Roman', Times, serif;
    font-size: 20px;
}

/* Left Card */
.card1 {
    background-color: black;
}

.label1 {
    color: white;
}

.label1-2 {
    color: rgb(129,129,129);
    font-weight: bold;
}

.label1-3 {
    color: rgba(120, 120, 120, 0.7);
    font-weight: bold;
}

/* Middle Card */
.card2 {
    background-color: rgb(28, 28, 30);
}

.label2 {
    color: rgb(240, 240, 240);
}

.label2-2 {
    color: rgb(129,129,129);
    font-weight: bold;
}

.label2-3 {
    color: rgba(120, 120, 120, 0.7);
    font-weight: bold;
}

/* Right Card */
.card3 {
    background-color: white;
}

.label3 {
    color: black;
}

.label3-2 {
    color: rgb(120, 120, 120);
    font-weight: bold;
}

.label3-3 {
    color: rgba(120, 120, 120, 0.7);
    font-weight: bold;
}