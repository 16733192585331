.animated-name-path {
    position: absolute;
    z-index: 4;
  }
  

.name-path {
    stroke-dasharray: 200;
    stroke-dashoffset: 200;
    animation: drawname 8s linear infinite;
  }
  
  @keyframes drawname {
    0% {
      stroke-dashoffset: 200;
    }
    50% {
      stroke-dashoffset: 0;
    }
    100% {
      stroke-dashoffset: -200; /* Adjust this value to ensure the line travels the full path */
    }
  }
  