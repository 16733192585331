  
  .mode-switch {
    height: 44px;
    width: 80px;
    border-radius: 100px;
    position: absolute;
    top: 3px;
    transition: left 0.3s ease-in-out, background-color 0.3s ease-in-out;
  }
  
  .mode-left-position {
    left: 3px;
  }
  
  .mode-right-position {
    left: 87px;
  }
  
  .mode-left-label,
  .mode-right-label {
    position: relative;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 50%;
  }
  
  .mode-left-label img,
  .mode-right-label img {
    width: 30px;
    height: 30px;
  }
  
  #mode-left + label,
  #mode-right + label {
    border-radius: 100px;
  }
  
  #mode-left:checked + label,
  #mode-right:checked + label {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 50%;
  }
  
  .black-font {
    color: black !important;
  }
  
 