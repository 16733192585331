.home-page-container{
  padding-top: 25px;
}

.profile-skills-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

/* Apple divider 1 */
.about-project-divider-light {
  margin: 40px auto 60px auto;
  height: 5px;
  border-radius: 3px;
  width: 1000px;
  background: linear-gradient(to right,
  rgba(26, 77, 72, 0.8), rgba(16, 98, 75, 0.8),
  rgba(166, 107, 255, 0.8), rgba(205, 93, 252, 0.8));
}

.about-project-divider-dark {
  margin: 40px auto 60px auto;
  height: 5px;
  border-radius: 3px;
  width: 1000px;
  background: linear-gradient(to right,
  rgba(26, 77, 72, 0.9), rgba(16, 98, 75, 0.9),
  rgba(166, 107, 255, 0.9), rgba(205, 93, 252, 0.9));
}

.project-experience-divider-light {
  margin: 40px auto 60px auto;
  height: 5px;
  border-radius: 3px;
  width: 1000px;
  background: linear-gradient(to right,
  rgba(26, 77, 72, 0.8), rgba(16, 98, 75, 0.8),
  rgba(166, 107, 255, 0.8), rgba(205, 93, 252, 0.8));

}

.project-experience-divider-dark {
  margin: 40px auto 60px auto;
  height: 5px;
  border-radius: 3px;
  width: 1000px;
  background: linear-gradient(to right,
  rgba(26, 77, 72, 0.9), rgba(16, 98, 75, 0.9),
  rgba(166, 107, 255, 0.9), rgba(205, 93, 252, 0.9));

}

.js-projects-container {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  gap: 80px;
}

.pc-projects-container {
  margin-top: 80px;
  display: flex;
  justify-content: center;
  gap: 80px;
}

.experience-container {
  position: relative;
  margin-top: 40px;
  display: flex;
  flex-wrap: wrap;
  gap: 40px;
  justify-content: center;
}  

/* Anon Theme Home Page */
.profile-skills-anon-container {
  padding-top: 40px;
  display: flex;
  flex-wrap: wrap;
  gap: 40px;
  position: relative;
  justify-content: center;
  z-index: 2;
}

.js-projects-anon-container {
  margin-top: 40px;
  display: flex;
  gap: 80px;
  position: relative;
  justify-content: center;
  z-index: 2;
}

.pc-projects-anon-container {
  margin-top: 80px;
  display: flex;
  gap: 80px;
  position: relative;
  justify-content: center;
  z-index: 2;
}

.experience-container-anon {
  margin-top: 40px;
  display: flex;
  flex-wrap: wrap;
  gap: 40px;
  justify-content: center;
  position: relative;
  z-index: 2;
} 

/* Footer Classes - Anon */
.footer-anon-light {
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 70px;
  margin-bottom: 0px;
  height: 110px;
  background-color: rgba(187, 187, 187, 0.9);
  border-top: 1px solid rgba(0,0,0,0.2);
  box-shadow: 0px -10px 20px rgba(0, 0, 0, 0.2);
}

.footer-anon-dark {
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 70px;
  margin-bottom: 0px;
  height: 110px;
  background-color: rgba(13, 13, 13, 0.9);
  box-shadow: 0px -10px 20px rgba(63, 63, 63, 0.2);
}

.anon-divider-centerer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.anon-divider-container {
  position: relative;
  z-index: 2;
  margin-top: 30px;
  height: 60px;
  display: flex;
  align-items: center;
}

.anon-divider-1 {
  position: relative;
  z-index: 2;
  width: 350px;
  height: 4px;
}

.anon-divider-2 {
  position: relative;
  z-index: 2;
  width: 200px;
  height: 8px;
}
